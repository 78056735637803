// ! ------------------------------------------
// ! Cambiar formato
// ! ------------------------------------------
import { combineReducers } from 'redux';
import { layoutReducer } from './LayoutReducer';

// * ------------------------------------------
// * Nuevo formato
// * ------------------------------------------
import { accesosReducer } from '../modules/accesos/AccesosReducer';
import { areasReducer } from '../modules/areas/AreasReducer';
import { loginReducer } from '../modules/auth/login/LoginReducer';
import { bancosReducer } from '../modules/bancos/BancosReducer';
import { clasesReducer } from '../modules/clases/clases/ClasesReducer';
import { clasesDocentesReducer } from '../modules/clases/clases_docentes/ClasesDocentesReducer';
import { CuposClasesReducer } from '../modules/clases/cupos/CuposClasesReducer';
import { docentesReducer } from '../modules/clases/docentes/DocentesReducer';
import { reservacionesClasesReducer } from '../modules/clases/reservaciones/ReservacionesClasesReducer';
import { tiposClasesReducer } from '../modules/clases/tipos/TiposClasesReducer';
import { codigosReducer } from '../modules/codigos/CodigosReducer';
import { contactosReducer } from '../modules/contactos/contactos/ContactosReducer';
import { tiposContactosReducer } from '../modules/contactos/tipos/TiposContactosReducer';
import { usersDeleteReducer } from '../modules/crm/delete_users/UsersDeleteReducer';
import { dashboardReducer } from '../modules/dashboard/DashboardReducer';
import { deliveryReducer } from '../modules/delivery/DeliveryReducer';
import { descuentosReducer } from '../modules/descuentos/descuentos/DescuentosReducer';
import { documentosReducer } from '../modules/documentos/documentos/DocumentosReducer';
import { tiposDocumentosReducer } from '../modules/documentos/tipos/TiposDocumentosReducer';
import { entidadBancosReducer } from '../modules/entidad_bancos/EntidadBancosReducer';
import { entidadesReducer } from '../modules/entidades/EntidadesReducer';
import { eventosReducer } from '../modules/eventos/eventos/EventosReducer';
import { tiposEventosReducer } from '../modules/eventos/tipos/TiposEventosReducer';
import { invitadosReducer } from '../modules/invitados/InvitadosReducer';
import { iotTunelesReducer } from '../modules/iot_tuneles/IotTunelesReducer';
import { iotsReducer } from '../modules/iots/IotsReducer';
import { metodosPagoReducer } from '../modules/metodos_pago/MetodosPagoReducer';
import { modulosReducer } from '../modules/modulos/ModulosReducer';
import { notificacionesReducer } from '../modules/notificaciones/notificaciones/NotificacionesReducer';
import { puertasReducer } from '../modules/puertas/PuertasReducer';
import { recibosReducer } from '../modules/recibos/RecibosReducer';
import { referidosReducer } from '../modules/referidos/ReferidosReducer';
import { reservasReducer } from '../modules/reservas/ReservasReducer';
import { reservasEspecialesReducer } from '../modules/reservasEspeciales/ReservasEspecialesReducer';
import { reservasRestauranteReducer } from '../modules/restaurantes/reservas_restaurante/ReservasRestauranteReducer';
import { restaurantesReducer } from '../modules/restaurantes/restaurantes/RestaurantesReducer';
import { tiposRestauranteReducer } from '../modules/restaurantes/tipos/TiposRestauranteReducer';
import { serviciosReducer } from '../modules/servicios/servicios/ServiciosReducer';
import { tiposServiciosReducer } from '../modules/servicios/tipos/TiposServiciosReducer';
import { settingsReducer } from '../modules/settings/SettingsReducer';
import { subareasReducer } from '../modules/subareas/SubAreasReducer';
import { tiposInvitacionReducer } from '../modules/tipos_invitacion/TiposInvitacionReducer';
import { usuariosReducer } from '../modules/usuarios/UsuariosReducer';

const rootReducer = combineReducers({
  restaurantes: restaurantesReducer,
  tiposRestaurante: tiposRestauranteReducer,
  accesos: accesosReducer,
  areas: areasReducer,
  servicios: serviciosReducer,
  tiposServicios: tiposServiciosReducer,
  bancos: bancosReducer,
  clases: clasesReducer,
  docentes: docentesReducer,
  clasesDocentes: clasesDocentesReducer,
  codigos: codigosReducer,
  contactos: contactosReducer,
  cuposClases: CuposClasesReducer,
  dashboard: dashboardReducer,
  delivery: deliveryReducer,
  documentos: documentosReducer,
  entidades: entidadesReducer,
  entidadBancos: entidadBancosReducer,
  eventos: eventosReducer,
  invitados: invitadosReducer,
  iots: iotsReducer,
  iotTuneles: iotTunelesReducer,
  layout: layoutReducer,
  login: loginReducer,
  modulos: modulosReducer,
  puertas: puertasReducer,
  recibos: recibosReducer,
  reservas: reservasReducer,
  reservacionesClases: reservacionesClasesReducer,
  reservasEspeciales: reservasEspecialesReducer,
  settings: settingsReducer,
  subareas: subareasReducer,
  tiposClases: tiposClasesReducer,
  tiposContactos: tiposContactosReducer,
  tiposDocumentos: tiposDocumentosReducer,
  tiposInvitacion: tiposInvitacionReducer,
  tiposEventos: tiposEventosReducer,
  usuarios: usuariosReducer,
  metodosPago: metodosPagoReducer,
  notificaciones: notificacionesReducer,
  usersDelete: usersDeleteReducer,
  descuentos: descuentosReducer,
  referidos: referidosReducer,
  reservasRestaurante: reservasRestauranteReducer,
});

export default rootReducer;
