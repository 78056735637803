import { Autocomplete, FormControl, Grid, TextField } from '@mui/material';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import React from 'react';
import * as Redux from 'react-redux';
import { getAllReservasService } from '../ReservasService';
import { getDateTimezone } from '../../../helpers';

const buildDate = (date, time) => {
  const [h, m, s] = time.split(':');
  return dayjs(date)
    .set('hour', h)
    .set('minute', m)
    .set('second', s)
    .set('millisecond', 0)
    .toDate();
};

const ReservasHours = ({
  area,
  subarea,
  onChange = () => {},
  onChangeCurrenReservations,
}) => {
  const { values, setFieldValue, errors, validateField } = useFormikContext();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const [reservations, setReservations] = React.useState([]);
  const [schedules, setSchedules] = React.useState([]);
  const [value, setValue] = React.useState([]);
  const [value2, setValue2] = React.useState([]);
  const [currentReservations, setCurrentReservations] = React.useState([]);
  const timezone = entidadesStore.activo.country.timezone;
  const utcOffset = entidadesStore.activo.country.utcOffset || 'UTC-04:00';

  const buildBlockReservation = (startTime, endTime, date) => {
    const { interval } = subarea;
    const startDate = getDateTimezone(dayjs(buildDate(date, startTime)).format('YYYY-MM-DD HH:mm:ss'), timezone);
    const endDate = getDateTimezone(dayjs(buildDate(date, endTime)).format('YYYY-MM-DD HH:mm:ss'), timezone);
    const reservations = [];
    let currentDate = startDate;
    let nextDate = dayjs(currentDate).add(interval, 'minute').toDate();
    let i = 0;
    while (currentDate < endDate) {
      reservations.push({
        id: i,
        label: `${dayjs(currentDate).utcOffset(utcOffset).format('HH:mm')}`,
        departureTime: nextDate,
        entryTime: currentDate,
      });
      currentDate = nextDate;
      nextDate = dayjs(currentDate).add(interval, 'minute').toDate();
      i++;
    }
    return reservations;
  };

  React.useEffect(() => {
    if (!area || !subarea || !values.date) {
      return setReservations(null);
    }

    const date = dayjs(values.date);

    const { blockReservation } = area;
    const { dates, interval } = subarea;
    const dateDayIndex = date.day();

    const subareaDate = dates.find((el) => el.day === dateDayIndex);

    const { startTime, endTime } = subareaDate;
    if (blockReservation === 'day') {
      const reservations = [
        {
          entryTime: buildDate(date, startTime),
          departureTime: buildDate(date, endTime),
        },
      ];
      setReservations(reservations);
      setSchedules([
        {
          id: 1,
          label: 'Todo el día',
        },
      ]);
      setValue({
        id: 1,
        label: 'Todo el día',
      });
      setValue2([]);
    } else {
      setReservations([]);
      setSchedules(buildBlockReservation(startTime, endTime, date.toDate()));
      setValue(null);
      setValue2([]);
    }
  }, [values.date, area, subarea]);

  React.useEffect(() => {
    onChange(reservations);
    setTimeout(() => {
      validateField('reservations');
    });
  }, [reservations]);

  React.useEffect(() => {
    if (values.subareaId) {
      const fetchReservations = async () => {
        const res = await getAllReservasService({
          subareaId: values.subareaId,
          date: values.date,
          entityId: entidadesStore.activo.id,
          perMonth: area?.blockReservation === 'day',
        });
        const result = res.data.data.map((el) => ({
          ...el,
          departureTime: new Date(el.departureTime),
          entryTime: new Date(el.entryTime),
        }));
        setCurrentReservations(result);
        onChangeCurrenReservations(result);
      };
      fetchReservations();
    }
  }, [values.subareaId, values.date]);

  return (
    <Grid item xs={12} md={12}>
      <FormControl fullWidth>
        {area?.blockReservation === 'day' ? (
          <Autocomplete
            fullWidth
            disableClearable
            id="combo-box-horario"
            value={value}
            size="small"
            options={schedules}
            disabled={area?.blockReservation === 'day'}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label="Horarios"
                  placeholder="Buscar Horarios"
                />
              );
            }}
            key={(option) => option.id}
            onChange={(e, value) => {
              // setFieldValue('areaId', value?.id || null);
            }}
          />
        ) : (
          <Autocomplete
            fullWidth
            disableClearable
            id="combo-box-horarios-2"
            value={value2}
            options={schedules}
            disableCloseOnSelect
            multiple
            size="small"
            disabled={area?.blockReservation === 'day'}
            getOptionDisabled={(option) => {
              const now = new Date(dayjs().utcOffset(utcOffset).format())
              if (option.entryTime < now) {
                return true;
              }

              const reservation = currentReservations.find((el) => {
                return (
                  el.entryTime.getTime() === option.entryTime.getTime() &&
                  el.departureTime.getTime() === option.departureTime.getTime()
                );
              });

              if (reservation && reservation.subarea.maxPersonsShared > 1) {
                const maxPersonsShared = reservation.subarea.maxPersonsShared;
                if (maxPersonsShared - reservation.personsShared > 0) {
                  return false;
                }
              }

              return !!reservation;
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label="Horarios"
                  placeholder="Buscar Horarios"
                  onBlur={() => {
                    validateField('reservations');
                  }}
                  helperText={errors.reservations || null}
                  error={!!errors.reservations}
                />
              );
            }}
            key={(option) => option.id}
            onChange={(e, value) => {
              setReservations(
                value.map((el) => ({
                  departureTime: el.departureTime,
                  entryTime: el.entryTime,
                }))
              );
              setValue2(value);
            }}
          />
        )}
      </FormControl>
    </Grid>
  );
};

export default ReservasHours;
